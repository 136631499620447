<template>
  <div :class="$style.item">
    <div :class="$style.box">
      <div :class="$style.left">
        <img :src="data.icon" />
      </div>
      <div :class="$style.center">
        <div :class="$style.name">{{ data.name }}</div>
        <div class="ellipsis-width" :class="$style.summary">{{ data.summary }}</div>
        <div :class="$style.price">{{ data.price }}</div>
      </div>
      <div :class="$style.right">
        <div v-if="data.is_open" :class="$style.tag">已开通</div>
        <i :class="$style.detail" class="ri-arrow-right-line" @click="onDetail()" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  methods: {
    onDetail() {
      this.$router.push({
        name: 'centerServiceDetail',
        query: {
          id: this.data.id,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.item {
  width: 100%;
  height: 130px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 20px;
  .box {
    display: flex;
  }

  .left {
    margin-right: 20px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .center {
    flex: 1;

    .name {
      font-size: 16px;
      margin-bottom: 6px;
    }

    .summary {
      font-size: 12px;
      color: #5c5c5c;
      margin-bottom: 20px;
    }

    .price {
      color: @error-color;
      font-size: 14px;
    }
  }

  .right {
    width: 60px;
    position: relative;

    .tag {
      float: right;
      width: 60px;
      height: 24px;
      font-size: 12px;
      text-align: center;
      border: 1px solid #eee;
      color: rgba(92, 92, 92, 100);
      background: #fafafa;
      border-radius: 100px;
      line-height: 24px;
    }

    .detail {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 20px;
    }
  }
}

.item:hover {
  .detail {
    display: inline-block;
  }
}
</style>
