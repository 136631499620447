<template>
  <div :class="$style.home">
    <div :class="$style.type">
      <div
        v-for="item in typeData"
        :key="item.id"
        :class="[$style.typeItem, type === item.id ? $style.active : null]"
        @click="onChoose(item.id)"
      >
        <i :class="item.icon" />
        {{ item.name }}
      </div>
    </div>
    <a-row :gutter="[20, 20]">
      <a-col
        v-for="item in data"
        :key="item.id"
        :sm="{ span: 12 }"
        :md="{ span: 12 }"
        :lg="{ span: 12 }"
        :xl="{ span: 8 }"
        :xxl="{ span: 6 }"
      >
        <Item :data="item" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { centerServiceService } from '@/service'

import Item from './item.vue'

const service = new centerServiceService()

export default {
  name: 'Home',
  components: {
    Item,
  },
  data() {
    return {
      typeData: [],
      data: [],
      type: 0,
      page: 1,
      pageSize: 10,
    }
  },
  created() {
    this.getType()
    this.getList()
  },
  methods: {
    onChoose(type) {
      this.type = type
      this.getList()
    },
    async getList() {
      try {
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
          category_id: this.type,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {}
    },
    async getType() {
      try {
        const { data } = await service.getType()
        this.typeData = [
          {
            id: 0,
            name: '全部服务',
            icon: 'ri-apps-2-line',
          },
        ].concat(data)
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.home {
  .type {
    display: flex;
    margin-bottom: 20px;

    &-item {
      font-size: 16px;
      color: #000;
      margin-right: 30px;
      cursor: pointer;

      i {
        font-size: 20px;
        vertical-align: -4px;
      }
    }

    &-item:hover {
      color: @primary-color;
    }

    .active {
      color: @primary-color!important;

      i {
        color: @primary-color!important;
      }
    }
  }

  .box {
    //
  }
}
</style>
